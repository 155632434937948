export const themes = [
    {
        name: 'typography-theme-alton',
        title: 'Alton',
        requireTheme: () => import('typography-theme-alton'),
    },
    {
        name: 'typography-theme-bootstrap',
        title: 'Bootstrap',
        requireTheme: () => import('typography-theme-bootstrap'),
    },
    {
        name: 'typography-theme-de-young',
        title: 'de Young',
        requireTheme: () => import('typography-theme-de-young'),
    },
    {
        name: 'typography-theme-doelger',
        title: 'Doelger',
        requireTheme: () => import('typography-theme-doelger'),
    },
    {
        name: 'typography-theme-elk-glen',
        title: 'Elk Glen',
        requireTheme: () => import('typography-theme-elk-glen'),
    },
    {
        name: 'typography-theme-fairy-gates',
        title: 'Fairy Gates',
        requireTheme: () => import('typography-theme-fairy-gates'),
    },
    {
        name: 'typography-theme-funston',
        title: 'Funston',
        requireTheme: () => import('typography-theme-funston'),
    },
    {
        name: 'typography-theme-github',
        title: 'Github',
        requireTheme: () => import('typography-theme-github'),
    },
    {
        name: 'typography-theme-grand-view',
        title: 'Grand View',
        requireTheme: () => import('typography-theme-grand-view'),
    },
    {
        name: 'typography-theme-irving',
        title: 'Irving',
        requireTheme: () => import('typography-theme-irving'),
    },
    {
        name: 'typography-theme-judah',
        title: 'Judah',
        requireTheme: () => import('typography-theme-judah'),
    },
    {
        name: 'typography-theme-kirkham',
        title: 'Kirkham',
        requireTheme: () => import('typography-theme-kirkham'),
    },
    {
        name: 'typography-theme-lawton',
        title: 'Lawton',
        requireTheme: () => import('typography-theme-lawton'),
    },
    {
        name: 'typography-theme-lincoln',
        title: 'Lincoln',
        requireTheme: () => import('typography-theme-lincoln'),
    },
    {
        name: 'typography-theme-moraga',
        title: 'Moraga',
        requireTheme: () => import('typography-theme-moraga'),
    },
    {
        name: 'typography-theme-noriega',
        title: 'Noriega',
        requireTheme: () => import('typography-theme-noriega'),
    },
    {
        name: 'typography-theme-ocean-beach',
        title: 'Ocean Beach',
        requireTheme: () => import('typography-theme-ocean-beach'),
    },
    {
        name: 'typography-theme-parnassus',
        title: 'Parnassus',
        requireTheme: () => import('typography-theme-parnassus'),
    },
    {
        name: 'typography-theme-st-annes',
        title: 'St. Annes',
        requireTheme: () => import('typography-theme-st-annes'),
    },
    {
        name: 'typography-theme-stern-grove',
        title: 'Stern Grove',
        requireTheme: () => import('typography-theme-stern-grove'),
    },
    {
        name: 'typography-theme-stow-lake',
        title: 'Stow Lake',
        requireTheme: () => import('typography-theme-stow-lake'),
    },
    {
        name: 'typography-theme-sutro',
        title: 'Sutro',
        requireTheme: () => import('typography-theme-sutro'),
    },
    {
        name: 'typography-theme-twin-peaks',
        title: 'Twin Peaks',
        requireTheme: () => import('typography-theme-twin-peaks'),
    },
    {
        name: 'typography-theme-us-web-design-standards',
        title: 'US Web Design Standards',
        requireTheme: () => import('typography-theme-us-web-design-standards'),
    },
    {
        name: 'typography-theme-Wikipedia',
        title: 'Wikipedia',
        requireTheme: () => import('typography-theme-Wikipedia'),
    },
    {
        name: 'typography-theme-wordpress-2010',
        title: 'Wordpress Theme 2010',
        requireTheme: () => import('typography-theme-wordpress-2010'),
    },
    {
        name: 'typography-theme-wordpress-2011',
        title: 'Wordpress Theme 2011',
        requireTheme: () => import('typography-theme-wordpress-2011'),
    },
    {
        name: 'typography-theme-wordpress-2012',
        title: 'Wordpress Theme 2012',
        requireTheme: () => import('typography-theme-wordpress-2012'),
    },
    {
        name: 'typography-theme-wordpress-2013',
        title: 'Wordpress Theme 2013',
        requireTheme: () => import('typography-theme-wordpress-2013'),
    },
    {
        name: 'typography-theme-wordpress-2014',
        title: 'Wordpress Theme 2014',
        requireTheme: () => import('typography-theme-wordpress-2014'),
    },
    {
        name: 'typography-theme-wordpress-2015',
        title: 'Wordpress Theme 2015',
        requireTheme: () => import('typography-theme-wordpress-2015'),
    },
    {
        name: 'typography-theme-wordpress-2016',
        title: 'Wordpress Theme 2016',
        requireTheme: () => import('typography-theme-wordpress-2016'),
    },
    {
        name: 'typography-theme-wordpress-kubrick',
        title: 'Wordpress Kubrick',
        requireTheme: () => import('typography-theme-wordpress-kubrick'),
    },
];
