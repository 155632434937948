//Injection can be improved by font list memoization, thus preventing unnecessary refetching
function injectFonts(typography) {
    const fontsStr = getFontsStr(typography);
    if (fontsStr !== '' && typeof window !== 'undefined') {
        injectLink(getFontsLink(fontsStr));
    }
    else {
        // Fallback action if defined?
    }
}
function injectLink(link) {
    const typoElt = document.getElementById('typography.js');
    if (typoElt) {
        typoElt.insertAdjacentHTML('afterend', link);
    }
    else {
        // Fallback action if defined?
    }
}
// Can be extended into custom domain +
const getFontsStr = (typography) => typography?.options?.googleFonts != null
    ? typography.options.googleFonts
        .map(font => `${font.name.split(' ').join('+')}:${font.styles.join(',')}`)
        .join('|')
    : '';
/*
 TODO:
  - [href] be extended with custom domain for self hosted fonts
  - [href] may be compatible with local font assets
  */
const getFontsLink = (str, domain = 'https://fonts.googleapis.com/css?family=') => `<link href="${domain}${str}" rel="stylesheet" type="text/css" />`;
export default injectFonts;
