import React from 'react';
const SectionTool = ({ children, title }) => (<div style={{
    float: 'left',
    marginRight: 5,
    width: '45%',
}}>
    <span style={{
    fontSize: 10,
    lineHeight: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}}>
      {title}
    </span>
    <br />
    <div style={{
    position: 'relative',
    overflow: 'hidden',
}}>
      {children}
    </div>
  </div>);
export default SectionTool;
