import React from 'react';
import { parseUnit } from '../..';
import { contramap, getMonoid, ordNumber } from 'fp-ts/lib/Ord';
import { sort } from 'fp-ts/lib/Array';
import { fold } from 'fp-ts/lib/Monoid';
import { pipe } from 'fp-ts/lib/pipeable';
import Select from '../select/select';
const WeightMapper = {
    regular: '400',
    italic: '400italic',
};
const M = getMonoid();
export const prepareFamilyWeights = ({ weights = [], filterOutItalics = true, }) => {
    const newWeights = weights
        .map((weight) => WeightMapper[weight] || weight)
        .filter(weight => !(filterOutItalics && parseUnit(weight)[1] === 'italic'));
    const byNumber = pipe(ordNumber, contramap((p) => parseUnit(p)[0]));
    const O1 = fold(M)([byNumber]);
    return sort(O1)(newWeights);
};
const pickFamilyWeightValue = ({ weights = [], weight, filterOutItalics = true, }) => prepareFamilyWeights({ weights, filterOutItalics }).indexOf(weight.toString());
const onChange = (value) => (props) => {
    const weights = prepareFamilyWeights({
        weights: props.family.weights,
        filterOutItalics: props.filterOutItalics,
    });
    const newWeight = weights[+value];
    const newOptions = {
        ...props.options,
        ...(props.type && {
            [`${props.type}Weight`]: isNaN(+newWeight) ? newWeight : +newWeight,
        }),
    };
    const googleFont = newOptions.googleFonts?.find((font) => font.name === props.family.family) || { name: '', styles: [] };
    props.onChange({
        ...newOptions,
        googleFonts: [
            ...newOptions?.googleFonts?.filter((font) => font.name !== googleFont.name && font.name !== ''),
        ],
    });
};
const FontWeightTool = props => (<Select options={prepareFamilyWeights({
    weights: props.family.weights,
    filterOutItalics: props.filterOutItalics,
})} value={pickFamilyWeightValue({
    weights: props.family.weights,
    weight: props.weight,
    filterOutItalics: props.filterOutItalics,
})} style={{
    width: '100%',
}} onChange={value => onChange(value)(props)}/>);
export default FontWeightTool;
